import React from 'react'
import styled, {css} from 'styled-components';

import {FaBars} from 'react-icons/fa';
import classNames from 'classnames';

const Toggle = ({handleNavToggle, className, icon, primary, label, ...rest}: any) => {
  return (
    <StyledToggle className={classNames('text-blue-500 p-1 m-1', "animate__animated animate__fadeInRight", className)}
                  onClick={handleNavToggle}>
      <div className="flex flex-col align-middle items-center">
        {icon ?? <FaBars/>}
        {label ?? <></>}
      </div>
    </StyledToggle>)
}

const StyledToggle = styled.button<{ primary?: boolean }>`
    font-size: 1.5rem;
    display: block;
    min-width: 45px;
    min-height: 45px;

    ${props => props.primary && css`
        /* color: var(--primary-text-color) !important; */
        /* background: var(--primary-color) !important; */
    `}
    &:hover {
        cursor: pointer;
        /* color: var(--secondary-color) !important; */
        /* background: var(--surface1) !important; */
    }
`;

export default Toggle