'use client'

import {SLinkButton} from "@/components/atoms/button";
import {Account} from "@/lib/types";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import {usePathname, useSearchParams} from "next/navigation";
import * as React from "react";
import {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import styled from "styled-components";
import Toggle from "../../atoms/toggle";
import {Icon} from "@/components/atoms/icons";
import {CiLogin} from "react-icons/ci";
import {MdOutlineAccountCircle, MdOutlineMessage} from "react-icons/md";
import {AiOutlineLogout} from "react-icons/ai";
import {useGetUnreadMessagesQuery} from "@/lib/client/slices/messages";


interface Props {
  sidebar: JSX.Element | JSX.Element[] | React.ReactNode
  profile: Account | undefined
}

const StyledMobileMenu = styled.nav`

    position: fixed;
    left: 0;
    top: 54px;
    width: 100vw;
    height: 100vh;
    z-index: 998;

    &.hide {
        transform: translate3d(-100vw, 0, 0);
    }

    &.show {
        transform: translate3d(0vw, 0, 0);
        overflow: hidden;
        background-color: white;
    }

`


const StyledLogo = styled.div`
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-direction: row;

    .brand-name-container {
        display: flex;
        flex-direction: column;

        .brand-name {
            font-size: 20px;
            padding: 0 15px;
            transition: all ease 0.8s;
        }

        .brand-slogan {
            font-size: 10px;
            padding: 0 15px;
            transition: all ease 0.8s;
        }

    }

    .brand-logo {
        background: url("/images/logo_nav.png") no-repeat center/contain;
        width: 50px;
        height: 50px;
        border-radius: 5px;
        transition: all ease 0.8s;
    }

    &:hover {
        transition: all ease 0.3s;

        .brand-logo {
            transform: rotateZ(10deg);
            transition: all ease 0.3s;
        }
    }

`


const NotLoggedElement = () => (
  <div className="flex align-middle justify-center items-center p-1 m-1">
    <SLinkButton href={"/register"} passHref={true}><Icon icon={MdOutlineAccountCircle}
                                                          value={"Inscription"}/></SLinkButton>
    <SLinkButton className={"ml-2"} href={"/login"} passHref={true}><Icon icon={CiLogin}
                                                                          value={"Connexion"}/></SLinkButton>
  </div>
)


const LoggedElement = (profile: Account) => {
  const {data: notifs, status} = useGetUnreadMessagesQuery()

  return (
    <div className="flex align-middle justify-center items-center">
      <Link href="/messaging" title="Messagerie"
            className="flex flex-col align-middle justify-center items-center  hover:bg-blue-200 rounded p-1 m-1">
        <Icon icon={MdOutlineMessage} value={<span className="hidden md:block">Messages {notifs && notifs.unread > 0 &&
            <span
                className="rounded-2xl bg-red-700 text-white text-sm font-semibold px-2">{notifs.unread}</span>}</span>}/>
      </Link>

      <Link href="/account" title="Mon compte"
            className="flex  flex-col align-middle justify-center items-center bg-blue-100 hover:bg-blue-200 rounded p-1 m-1">
        <Icon value={<span className="hidden md:block">{profile.account_display_name}</span>}><Image
          src={profile.account_image_url || '/images/logo.png'} height={24} width={24} alt="Image" className="rounded"/></Icon>
      </Link>

      <Link href="/logout" title="Déconnexion"
            className="flex flex-col align-middle justify-center items-center  hover:bg-blue-200 rounded p-1 m-1">
        <Icon icon={AiOutlineLogout}/>
      </Link>
    </div>
  )
}


export const HeaderMenu = (props: Props) => {
  const sidebarRef = React.useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    setMenuOpen(false)
  }, [pathname, searchParams])


  return (
    <>
      <div className="sticky top-0 z-50 w-full h-14 flex flex-row justify-center bg-white  border-b border-gray-200">
        <div className="flex flex-row w-full items-center xl:w-10/12 ">
          <div className="flex-none xl:hidden">
            <Toggle handleNavToggle={() => setMenuOpen(!menuOpen)}/>
          </div>
          <div>
            <Link href={"/"}>
              <StyledLogo className="flex">
                <div className="brand-logo hidden md:block" title="Les Petites Patounes"/>
                <div className="brand-name-container hidden md:block">
                  <div className={"brand-name hidden md:block"}>Les Petites Patounes</div>
                  <div className={"brand-slogan hidden md:block"}>Vos animaux toujours avec vous</div>
                </div>
              </StyledLogo>
            </Link>
          </div>
          <div className="grow "></div>
          <div className="flex-none">
            {props.profile ? LoggedElement(props.profile) : NotLoggedElement()}
          </div>
        </div>
      </div>

      <StyledMobileMenu className={classNames('xl:hidden', menuOpen ? 'show' : 'hide')}
                        ref={sidebarRef}></StyledMobileMenu>
      {sidebarRef.current && props.sidebar && createPortal(props.sidebar, sidebarRef.current)}
    </>
  )
}

