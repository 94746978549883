'use client'

import {useRouter} from "next/navigation";
import {HTMLAttributes, PropsWithChildren, Suspense, useEffect, useState} from "react";
import {HeaderMenu} from "../organisms/menus/menu";
import {LoadingSpinner} from "@/components/atoms/loading";
import Toggle from "@/components/atoms/toggle";
import classNames from "classnames";
import {FaFilter} from 'react-icons/fa';
import {Account} from "@/lib/types";

interface Props {
  sidebar: any,
  lang?: string,
  profile?: Account | undefined
}

// max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-none md:flex-row xl:w-10/12
export const Layout = ({children, sidebar, profile, ...rest}: PropsWithChildren<Props>) => {
  return <div className="flex flex-col items-center bg-default min-h-screen">
    <HeaderMenu {...rest} sidebar={sidebar} profile={profile}/>
    <div className="flex flex-col w-full md:w-10/12 ">
      <div className="relative z-20 w-full">{/* SUBMENU AVAILABLE HERE*/}</div>
      <div className="flex flex-col md:flex-row">
        <SidebarLayout className="hidden xl:flex xl:flex-row xl:p-2 xl:w-72">{sidebar}</SidebarLayout>
        <div className="flex flex-col w-full lg:flex-row ">{children}</div>
      </div>
      <div className="relative z-20 w-full">{/* SUBMENU AVAILABLE HERE*/}</div>
    </div>
  </div>
}

export const ContentLayout = ({children, ...rest}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <div {...rest} className={classNames('flex flex-col w-full overflow-hidden  p-2')}>
    <Suspense fallback={<LoadingSpinner/>}>
      {children}
    </Suspense>
  </div>
}

export const AsideLayout = ({children, ...rest}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return <div {...rest} className={classNames("p-2 overflow-hidden md:w-6/12 lg:w-4/12 xl:w-4/12")}>
    {children}
  </div>
}


export const SidebarLayout = ({children, buttonLabel, ...rest}: PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  buttonLabel?: string
}) => {

  const [menuOpen, setMenuOpen] = useState(false)
  const router = useRouter()

  useEffect(() => {
    const handleStart = (url: string) => {
      console.log(`sidebar loading: ${url}`)
      setMenuOpen(false)
    }

    const handleStop = () => {
      setMenuOpen(false)
      console.log(`sidebar loaded!`)
    }

    // router.events.on('routeChangeStart', handleStart)
    // router.events.on('routeChangeComplete', handleStop)
    // router.events.on('routeChangeError', handleStop)

    return () => {
      // router.events.off('routeChangeStart', handleStart)
      // router.events.off('routeChangeComplete', handleStop)
      // router.events.off('routeChangeError', handleStop)
    }
  }, [router])


  return <>
    {/* <ToggleSidebar onClick={() => setMenuOpen(!menuOpen)}>{buttonLabel? buttonLabel : 'Filtres'}</ToggleSidebar> */}
    <div {...rest}>
      {children}
      {/* <ToggleSidebar onClick={() => setMenuOpen(!menuOpen)}>Fermer</ToggleSidebar> */}
    </div>
  </>
}

interface OptionsLayoutProps extends Omit<PropsWithChildren<HTMLAttributes<HTMLDivElement>>, 'title'> {
  title: any
}

export const OptionsLayout = ({children, title, ...rest}: OptionsLayoutProps) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return <div className="">
    <div
      className={classNames("bg-secondary p-2 rounded-t hover:bg-secondary text-white cursor-pointer font-semibold", {'rounded-b mb-2': !menuOpen})}>
      <div onClick={() => setMenuOpen(!menuOpen)}>{title}</div>
    </div>
    <div className={classNames("bg-white border-b-2 border-blue-100 p-2 rounded-b mb-2", {'hidden': !menuOpen})}>
      {children}
    </div>

    <div {...rest} className={classNames("hidden", {'bg-red-100': menuOpen})}>
      <div className="mobile"><Toggle handleNavToggle={() => setMenuOpen(!menuOpen)} icon={<FaFilter/>} primary
                                      label="Filtres"/></div>
      {children}
    </div>
  </div>
}